import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

export const uploadPost = async (body) => {
    const { data } = await axios.post(`/endpoints/event_post_register`, body);

    return data;
}

export const postDetail = async (post_index) => {
    const { data } = await axios.post(`/endpoints/event_post_detail`, {
        post_index
    })

    return data;
}

export const uploadImage = async (file) => {
    const formData = new FormData();

    formData.append('files', file);

    const { data } = await axios.post(`/files`, formData, {
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
        },
    });


    return data;
}

export const getChartData = async (index, type, session, user_index) => {
    console.log(type, 'tokenType');
    const { data } = await axios.post(`/endpoints/trade_price_info`,
        {
            tokens_index: index,
            type: type,
            period: '1d',
        },
        {
            headers: {
                Authorization: session,
                UserAgent: user_index
            }
        }
    )


    return data;
}

export const getPointChartData = async (index, type, session, user_index) => {
    const { data } = await axios.post(`/endpoints/trade_price_info_point`,
        {
            points_index: index,
            type: type,
            period: '1d',
        },
        {
            headers: {
                Authorization: session,
                UserAgent: user_index
            }
        }
    )

    return data;
}

export const eventAirdrop = async ({ email, session, user_index }) => {
    const { data } = await axios.post('/endpoints/receive_event_winnings',
        {
            email: email
        },
        {
            headers: {
                Authorization: session,
                UserAgent: user_index
            }
        }
    )

    return data;
}